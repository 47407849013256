// Helper classes

// Hide visually and from screen readers: h5bp.com/u
.hidden {
    display: none !important;
    visibility: hidden;
}

// Hide only visually, but have it available for screen readers: h5bp.com/v
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


// Extends the .visuallyhidden class to allow the element to be focusable
// when navigated to via the keyboard: h5bp.com/p
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

// Hide visually and from screen readers, but maintain layout
.invisible {
    visibility: hidden;
}

// Clearfix
.clearfix {
    @include clearfix();
}

.clear {
    clear: both;
}

// utility etc
.hide {
  display:none;
}
.desktop-only {
  display:none;
  @media screen and (min-width: $breakpoint-l) {
    display:initial;
  }
}