//
// Mixin library
//

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}