//
// GLOBAL VARIABLES
//

$base-font-size: 1em;
$base-line-height: 2em;
$base-font-colour: #494745;
$base-link-colour: #4491c0;
$base-font-stack: sans-serif; //"Rooney","rooney-web", "Palatino", "Calisto MT", "Times New Roman", serif;
$secondary-font-stack: sans-serif; // "Proxima Nova","proxima-nova", "Century Gothic", "Trebuchet MS", "Helvetica Neue", "Helvetica", Arial, sans-serif;
$secondary-nav-color : #4491c0;

// Buttons
$btn-primary-colour: #3ac97b;
$btn-primary-border-colour: #3ac97b;
$btn-primary-text-colour: #fff;
$btn-secondary-colour: #ffba4b;
$btn-secondary-border-colour: #ffba4b;
$btn-secondary-text-colour: #fff;
$btn-basic-colour: #f7f6f4;
$btn-basic-border-colour: #f7f6f4;
$btn-basic-text-colour: #3ac97b;

// Media Queries
$xsmall-screen: 480px !default;
$small-screen: 768px !default;
$medium-screen: 1080px !default;
$large-screen: 1340px !default;
$xlarge-screen: 1640px !default;
$xxlarge-screen: 1800px !default;
$mobile-nav-max: 800px !default;
$short-height-screen: 650px !default;

$screen: "only screen" !default;

$xsmall: "only screen and (min-width:#{$xsmall-screen})" !default;
$small: "only screen and (min-width: #{$small-screen})" !default;
$small-only: "#{$screen} and (max-width: #{$xsmall-screen})" !default;

$medium: "only screen and (min-width:#{$medium-screen})" !default;
$medium-only: "#{$screen} and (min-width:#{$xsmall-screen+1}) and (max-width: #{$medium-screen})" !default;

$large: "only screen and (min-width:#{$large-screen})" !default;
$xlarge: "only screen and (min-width:#{$xlarge-screen})" !default;

$large-only: "#{$screen} and (min-width:#{$medium-screen+1}) and (max-width: #{$large-screen})" !default;

$xxlarge: "only screen and (min-width:#{$xxlarge-screen})" !default;
$landscape: "only screen and (orientation: landscape)" !default;
$portrait: "only screen and (orientation: portrait)" !default;
$menuswap: "only screen and (max-width:#{$mobile-nav-max})" !default;
$menuswap-min: "only screen and (min-width:#{$mobile-nav-max+1})" !default;
$short-screen-max: "(max-height:#{$short-height-screen})" !default;
$short-screen-min: "(min-height:#{$short-height-screen+1})" !default;

$em-base: $base-font-size !default;

// We use these to control text direction settings
$text-direction: ltr !default;





//Custom variables

$brand-yellow:        #FFE75D;
$brand-light-gray:    #E1E2F1;
$brand-lighter-gray:  #F3F5FB;
$brand-light-purple:  #A3A6CC;
$brand-purple:        #563674;
$brand-black:         #3D3F4D;
$brand-green:         #00B38F;

$breakpoint-xs:   360px;
$breakpoint-s:    640px;
$breakpoint-m:    800px;
$breakpoint-l:    960px;
$breakpoint-xl:   1200px;
$breakpoint-xxl:  1600px;
