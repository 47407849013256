.documentation {

  .section__header {
      h1, .h1 {
          margin-bottom:0;
          line-height:1.125;
          @media only screen and (min-width: 640px) {
            width:9em;
          }
      }
  }

  *:focus {
    outline:none;
  }
  h2, .h2 {
    font-size:2em;
    padding-top:1.5em;
  }
  h3 {
    font-size:1.5em;
    padding-top:1em;
  }
  h4, h5, h6 {
    font-size:1em;
    margin-top:1em;
    padding-top:1em; /* this offsets headings from the top of the page when anchored to */
  }

  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin:6em 0 2em;
  }

  .js-toc-wrapper {
    display:none; // 
    @media only screen and (min-width: 820px) {
      display:block;
      position:relative; 
      float:right; 
      width:8em;
    }
    @media only screen and (min-width: 960px) {
      width:12em;
    }
    @media only screen and (min-width: 1280px) {
      width:8em;
    }
  }

  .js-toc {
    position:absolute; 
    top:3em; 
    padding-right:1em;
    ol,
    ul,
    li {
      list-style:none;
      margin:0;
      a {
        display:block;
        line-height:1.25em;
        padding-top:0.5em;
        padding-bottom:0em;
      }
    }
    .toc-link::before {
      height:inherit;
//      height:4em;
      height:1.5em;
      margin-top:-0.25em;
    }
    .is-active-link::before {
      background-color:$brand-green;
    }
    .is-collapsed .toc-link::before {
      display:none;
    }
  }


  .js-toc-content {
    @media only screen and (min-width: 820px) {
      margin-right:12em;
    }
    @media only screen and (min-width: 960px) {
      margin-right:16em;
    }
    @media only screen and (min-width: 1280px) {
      margin-right:12em;
    }
    > h2 {
      margin-top:0;
    }
    margin-bottom:24em; 
//    max-width:32em; 
    ul,
    ol {
      padding-left:1em;
      list-style:default;
    }
    > ol { 
      position:relative;
    }
    ol { 
      counter-reset: item; 
      li { 
       display:block;
       &:before { 
          content: counters(item, ".") ""; 
          counter-increment: item; 
          padding-right:0; 
          color:#ccc;
          position:absolute;
          left:0;
        }
      }
    }
    li { 
      display: list-item; 
    }
    hr {
      margin:4em 0 1em;
      page-break-before:always;
    }
  }


}