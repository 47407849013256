// Buttons

// .button,
// button,
// input[type="button"] {
//   border: none;
//   background: $btn-basic-colour;
//   border: 2px solid $btn-basic-border-colour;
//   border-radius: 5px;
//   display: block;
//   width: 100%;
//   color: $btn-basic-text-colour;
//   font-size: 1em;
//   line-height: 1.1em;
//   letter-spacing: 1px;
//   font-weight: 600;
//   font-family: $secondary-font-stack;
//   text-transform: uppercase;
//   padding: 0.7em 2.3em;
//   margin-bottom: 1em;
//   cursor: pointer;
//   text-align: center;
//   white-space: normal;
//   transition: color .5s, background .5s;
//   -webkit-appearance: none;
//   
//   @media #{$xsmall} {
//     display: inline-block;
//     width: auto;
//     letter-spacing: 2px;
//   }
//   
//   &:hover,
//   &:focus {
//     background: #fff;
//     color: $btn-basic-text-colour;
//   }
//   
//   &.primary {
//     background: $btn-primary-colour;
//     color: $btn-primary-text-colour;
//     border: none;
//     padding: 0.8em 2.5em;
//     
//     &:hover,
//     &:focus {
//       background: darken($btn-primary-colour,13%)
//     }
//   }
//   
//   &.secondary {
//     background: $btn-secondary-colour;
//     color: $btn-secondary-text-colour;
//     border: none;
//     padding: 0.8em 2.5em;
//     
//     &:hover,
//     &:focus {
//       background: darken($btn-secondary-colour,16%)
//     }
//   }
// }
// 
// input[type="submit"] {
//   display: inline-block;
//   border: none;
//   background: $btn-primary-colour;
//   border-radius: 5px;
//   color: #fff;
//   letter-spacing: 1px;
//   padding: 0.6em 2em;
//   cursor: pointer;
//   transition: color .5s, background .5s;
//   -webkit-appearance: none;
//   
//   &:hover,
//   &:focus {
//     background: darken($btn-primary-colour, 13%);
//   }
// }

a.cta--btn {
  font-size:  0.75em;
  background: $brand-black;
  color:      $brand-yellow;
  display:    inline-block;
  padding:    1em 1.333333em;
  margin:1em 0 2em;
  text-decoration:none;
  &:hover {
    text-decoration:underline;
    box-shadow:0 0em 0.125em rgba(0,0,0,0.25);
    position:relative;
    transition: box-shadow 0.125s ease;
  }
}
