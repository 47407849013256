@charset "UTF-8";
// Main CSS

// Global variables
@import 'variables';

// Mixins
@import 'mixins/mixins';

// Vendor
@import 'vendor/normalize';
@import 'vendor/tocbot';

// Basics
@import 'helpers';
@import 'base';

// Layout
@import 'layout/layout';
@import 'layout/layout.header';
@import 'layout/layout.footer';
@import 'layout/layout.contact';
@import 'layout/landingpage';
@import 'layout/layout.documentation';
@import 'layout/layout.pricing-tables';

// Partials
@import 'partials/AvenirNext';
@import 'partials/typography';
//@import 'partials/site.header';
//@import 'partials/site.footer';
@import 'partials/buttons';
// @import 'partials/tables';

