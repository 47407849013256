// Basic global styles


// TODO list:
// - @import "fonts/AvenirNext/AvenirNext";
// - (ongoing...) integrate these "flat" css styles into layouts and partials as appropriate


:root {
  --fontsize-xs:.75rem;
  --fontsize-s:1rem;
  --fontsize-m:1.25rem;
  --fontsize-l:1.5rem;
  --fontsize-xl:2rem;
  
  
  --yellow:        #FFE75D;
  --light-gray:    #E1E2F1;
  --lighter-gray:  #F3F5FB;
  --light-purple:  #A3A6CC;
  --purple:        #563674;
  --black:         #3D3F4D;
  --green:         #00B38F;
  
}

html,
body {
  margin:0;
  padding:0;
  font-family:'Avenir Next', 'Helvetica Neue', 'HelveticaNeue', 'Helvetica', sans-serif; /* TODO: this is wrong. Should be specifying the font with a uniquely named @font-face rule */
  color:$brand-black;
  overflow-x:hidden;
}


//
// typogrpahy
//

h1, .h1 {
  font-size:2em;
  line-height:1em;
  margin:0;
  display:block;
}
.h2,h2,h3,h4,h5,h6 {
  margin-top:2em;
  margin-bottom:0.5em;
  display:block;
}

//
// links
//

a { color:$brand-black; text-decoration:underline;}
a:hover,
a:visited { color:inherit; }



//
// site header
//

.main-nav {
  .active a {
    background:#F3F5FB;
  }
}




// Unsorted
#content {
  clear:both;
}

.page {
  background: white;
  margin:4em 1.5em;
}

table {
  border-spacing: 0;
}

.page .table thead th {
  text-align:left;
}


//
// responsive styles TODO: review these. Much of it is probably not in use
//

  @media only screen and (min-width: 480px) {
    h1, .h1 {
      font-size:3em;
    }
    .page {
      margin:4em 3.75em;
    }
  }
  @media only screen and (min-width: 728px) {
    .page {
      margin:8.5em 6em;
      min-height:45em;
    }

    /* TODO: hover behaviours should only be targeted to non touch devices */

  }


  @media only screen and (min-width: 960px) {
    .page {
      margin:8.5em 9.5em;
    }
  }

  @media only screen and (min-width: 1200px) {
    .page {
      margin:8.5em 12em;
    }
  }
  @media only screen and (min-width: 1420px) {
    body {
      font-size:1.25em;
    }
    .page {
      margin:8.5em 12em;
    }
  }

  @media only screen and (min-width: 1760px) {
    .page {
      margin:8.5em 16em;
    }
  }


//
//
// etc
.btn {
  font-size:1em;
  text-decoration:none;
}


body.search .btn {
    padding:0.5em 0.666666em 0.333em;
    display:inline-block;
    background:#00b38f;
    color:white;
    font-weight:600;
    letter-spacing:0.0625em;
    text-transform:uppercase;
    text-decoration:none;
}


/* Content Auditor */
body.contentauditor { //-home
  @media only screen and (min-width: 560px) {
    background:url(/images/contentauditor/bg--intro.png) no-repeat center 4em;
    background-size:100% auto;
  }
  @media only screen and (min-width: 880px) {
    background:url(/images/contentauditor/bg--intro.png) no-repeat center top;
    background-size:100% auto;
  }
  @media only screen and (min-width: 1152px) {
    background:url(/images/contentauditor/bg--intro.png) no-repeat right top;
    background-size: auto;
  }

  .header {
    @media only screen and (max-width: 747px) {
      height:auto;
      .nav {
        padding-top:0;
        float:none;
//        width:100%;
        margin:0 1em;
        overflow:hidden;
        span {
          display:inline-block;
          float:left;
          width:23%;
          margin:0 1%;
          text-align:center;
          a {
            margin:0;
            color:$brand-black;
            border:2px solid $brand-green;
            display:block;
            border-radius:0.25em;
          }
        }
      }
    }
  }
  a {
    color:$brand-green;
  }
  .section .section__header {
      h1, .h1 {
          display:block;
      }
  }
  .section--intro {
    h1, .h1 {
      max-width:12em;
    }
    .section__header .colour-bar {
      background-color:$brand-green;
      display:none;
    }
  }
  .section .section__header p {
    margin-bottom:1.5em;
  }
  .btn,
  .cta {
    color:white;
    background-color:$brand-green;
    text-decoration:none;
    padding:1em 2em;
    display:inline-block;
    text-align:center;
    &:hover {
      text-decoration:underline;
    }
  }


  .section--more-features {
    .btn--more-features {
      text-align:center;
      .btn {
        margin:0 auto;
        background:white;
        border:2px solid $brand-green;
        color:$brand-black;
        &:hover {
          background:$brand-green;
          color:white;
        }
      }
    }
  }


  .section--packages {
    h1, .h1 {
      text-align:center;
      .colour-bar {
        left:0;
      }
    }
    .account_descriptions {
      overflow:hidden;

      @media only screen and (min-width: 800px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;
      }

      .account_description {
        padding:1.5em 0 0;
        text-align:center;
        overflow:hidden;
        border-top:2px solid #E1E2F1;
        @media only screen and (min-width: 800px) {
          border:none;
          text-align:left;
          background:white;
          border-radius:0.25em;
          box-shadow:0 0 0.5em rgba(0,0,0,0.1);
        }
        h3 {
          font-weight:normal;
          font-size:1rem;
          text-align:center;
          margin:0;
        }
        p {
          min-height:6em;
          padding:0 1.5em;
          font-size:0.8em;
          font-style:italic;
          display:none; //TODO: temporarily hiding this
        }
        ul, ol {
          min-height:6em;
          padding:0;
          margin:0;
          @media only screen and (min-width: 800px) {
            padding:0 0 0 1em;
            margin:0 1.25em;
          }
        }
        li {
          margin:0;
          list-style:none;
          @media only screen and (min-width: 800px) {
            list-style:disc;
          }
        }
        .price {
          display:block;
          text-align:center;
          font-size:4em;
          font-weight:900;
          margin-bottom:1rem;
          letter-spacing:-0.05em;
          small {
            line-height:1em;
            display:block;
            font-size:1rem;
            font-weight:normal;
          }
          small,
          &.price--free {
            letter-spacing:0;
          }
        }
      }
      .cta--sign-up-now {
        text-align:center;

        @media only screen and (min-width: 800px) {
          grid-column: 1 / 5;
        }
      }
      .btn {
        font-size:1.5em;
        line-height:1.25em;
        box-shadow:0 0 0.5em rgba(0,0,0,0.1);
        display:block;
        clear:both;
        margin-bottom:1em;
        small {
          display:block;
          margin-top:0.5em;
          line-height:1.25em;
        }
      }
    }
  }

  &.tutorials-and-frequently-asked-questions {
    h3 {
      font-size:2em;
      margin-top:4em;
    }
    .item-list {
      ul ul {
        max-width:28em;
      }
      label {
        display:block;
        cursor:pointer;
      }
      input[type='radio'] {
        display: none;
      }
      .folder {
        overflow:hidden;
        max-height:0;
        transition: max-height 0.125s;
      }
      input[type='radio']:checked + .folder {
        display:block;
        max-height:500em;
        overflow:auto;
      }
    }

  }
}



// content styles


p.intro {
  font-size:1.5em;
  margin-left:0;
}
ul.intro,
ol.intro {
  font-size:1.25em;
}


.section--post {

  h2, .h2 {
    max-width:24em;
  }
  h3, .h3 {
    margin-top:4em;
    max-width:32em;
  }
  h4, .h4 {
    font-size:1.5rem;
  }

  h5, .h5 {
    font-size:1.25rem;
  }
  span.author {
    font-size:0.5em;
    font-size:1rem;
    font-weight:normal;
    display:block;
  }
  ul, ol {
    max-width:none;
    margin-bottom:2em;
    padding-left:1em;
  }

  figure {
    img {
      display:block;
      background:$brand-lighter-gray;
      background:white;
      text-align:center;
      width:100%;
    }
    margin-top:3em !important;
    &:first-child {
      margin-top:0 !important;
    }
  }

  @media screen and (min-width: 800px) {

    ul, ol,
    p {
      line-height:1.75em;
      margin:1.75rem 0;
      padding:0;
    }
  }

  p {
    line-height:1.666666em;
    line-height:1.333333em;
    line-height:1.5em;
    margin-bottom:1.5em;
    max-width:32em;
  }

}

body.articles {
  .section .section__header {
      h1 {
          display:block;
      }
  }
}



/* WebOps */

body.webops {
  a {
    color:$brand-black;
  }
}




/* Design system */
.version { color:$brand-green; font-weight:500; }

.tag{
  background:var(--lighter-gray);
  padding:0 0.333em;
  font-size:0.8em;
  font-weight:300;
  border:2px solid var(--black);
  color:var(--black);
}

.listing * {
  margin:0;
}
.listing a { 
  border-top:1px solid black;
  padding:1em 0;
  text-decoration:none;
  display:block;
}
.listing a:hover strong,
.listing a:hover p {
  text-decoration:underline;
}
