// Pricing Tables

/* -- section--pricing -- */
.section--pricing {
  padding:0 0.5em 6em;
}
.section--pricing .section__header h1 {
  font-size: 3.25em;
  line-height: 1.125em;
  margin-top: 4rem;
  margin-bottom:3rem;
  text-align:center;
  display:block;
}
.section--pricing .section__header .colour-bar {
  width:100vw;
  height:0.5rem;
  background:#3D3F4D;
}
.section--pricing .section__body {
  background:white;
  opacity:1;
  padding:0;
  width:auto;
  text-align:center;
  margin-bottom:20%;
  border-bottom:2rem solid transparent;
}
//&.search .section--pricing .section__body {
//  margin-bottom:0px;
//}
.section--pricing table {
  width:100%;
}
.section--pricing  td {
  padding: 0.625rem 0 0.375rem;
  border-top: 0.0625rem solid #E1E2F1;
}
.section--pricing  th {
  padding: 0.5em 0;
  font-weight:300;
  text-transform:uppercase;
  font-size:0.666em;
  letter-spacing:0.125em;
  border-bottom: 1px solid rgba(61,63,77,0.5);
}
.section--pricing table {
  font-size:1rem;
}
.section--pricing .table-features {
  text-align:left;
  font-size:1rem;
  line-height:1.5em;
  padding-left:1em;
  display:none; /* Hide on mobile */
}
.section--pricing table .xs-text {
  font-size: 0.666666em;
  text-transform: uppercase;
  display: block;
  line-height: 1em;
  margin-bottom: 0.5em;
}
.section--pricing td.table-highlight {
  font-weight:bold;
}
.icon--checkmark {
  display:inline-block;
  width:1.25rem;
  height:1.25rem;
  vertical-align: -0.1em;
  background: url(/images/webops/checkmark.png) no-repeat center center;
  background-size: contain;
}
@media screen and (min-width: 640px){
  .section--pricing {
    padding:0 2.5em 6em;
  }
  .section--pricing table {
    font-size:1.25rem;
  }
  .section--pricing .section__header h1 {
    font-size: 4.25em;
    text-align: left;
    margin-bottom:1.25rem;
  }
  .section--pricing .table-features {
    display:table-cell;
  }
  .section--pricing table .xs-text {
    display:none;
  }
  .section--pricing table .table-prices .xs-text {
    display:block;
  }
}
