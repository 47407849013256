// Site header




.header {
  z-index:1;
  position:relative;
  height:7em;
  padding:0 1em;
  @media screen and (min-width: $breakpoint-s) {
    padding:0 2em;
  }
  @media screen and (min-width: $breakpoint-m) {
    .btn--cta {
      display:none;
    }
  }
  overflow:hidden;
  a {
    font-weight:500;
    text-decoration:none;
  }
  .logo {
    height:3em;
    padding:1.5em 0;
    border-left:none;
    border-right:none;
    display:inline-block;
    .logo--stacked {
      display:none;
    }
    @media screen and (min-width: $breakpoint-s) {
      padding:2em 0;
      .logo--stacked {
        display:block;
      }
      .logo--horizontal {
        display:none;
      }
    }
    img {
        max-height:2em;
        max-width:100%;
        margin-top:0.25em;
        @media screen and (min-width: $breakpoint-s) {
          max-height:none;
          height:3.5em;
          margin-top:0;
        }
    }
  }
  .nav {
    display:block;
    float:right;
    padding:2em 0;
    @media screen and (min-width: $breakpoint-s) {
      padding:2.5em 0;
    }
    
    a {
      height:2em;
      margin-left:1em;
      line-height:2em;
      padding: 0 0.25em;
      font-size: 0.75em;
      @media screen and (min-width: $breakpoint-s) {
        font-size: 1rem;
        padding:0 0.5em;
      }
    }
  }
}