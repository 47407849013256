//
// Global layout
// This is where global site layout is managed. Specific layouts live in their respective layout partials.
//



// layouts

//resets

html, body {
  margin:0;
  padding:0;
}


// page layout
body {
  overflow-x:hidden;
  position:relative;
}



// section layout
.section {
  margin:0 auto;
  padding:0 2em 4em;
  @media screen and (min-width: $breakpoint-xs) {
    padding:0 2.5em 6em;
  }
  @media screen and (min-width: $breakpoint-s) {
//    padding:0 3.5em 8em;
    padding:0 5.5em 8em;
  }
  @media screen and (min-width: $breakpoint-l) {
    //width:54em;
    max-width:54em;
  }






  .section__header {
    .colour-bar {
      content:'';
      height:0.125em;
      height:2px;
      position:absolute;
      top:-0.25em;
      background:$brand-light-gray;
    }

    h1, .h1 {
      position:relative;
      display:inline-block;
    }

    p {
      font-size:1.25em;
      margin-bottom:1em;
      max-width:none;
      @media screen and (min-width: $breakpoint-s) {
        margin-left:8.333%;
        margin-right:1em;
        font-size:1.5em; // 16*1.5=24
        line-height:1.5; // = 48, better
        margin-bottom:1em;
      }
      @media screen and (min-width: $breakpoint-l) {
        margin-left:3em;
      }
    }
  }



  .points,
  .terms {
    overflow:hidden;
    .point,
    .term {
      margin-bottom:3em;
//      max-width:24em;
      max-width:32em;
      font-size:0.8125em;
      @media screen and (min-width: $breakpoint-xs) {
//        margin-left:8.333%;
      }
      @media screen and (min-width: $breakpoint-s) {
        margin-top:3em;
        margin-left:8.333%;
        font-size:1em;
        p {
          padding-right:1em;
        }

        ul, ol {
          list-style:circle;
          list-style-position: outside;
          margin-top:0.5em;
          margin-left:0;
          padding:0;
        }
        li {
//          margin-bottom:1.5em;
          margin-bottom:0.75em;
        }

      }
      @media screen and (min-width: $breakpoint-l) {
        float:left;
        margin-top:24px;
        margin-left:4.5em;
        width:13.5em;
      }
    }
    .point--2x {
      max-width:48rem;
      @media screen and (min-width: $breakpoint-m) {
        font-size:24px;
      }
      @media screen and (min-width: $breakpoint-l) {
        float:left;
        margin-left:4.5em;
        width:22.5em;
      }
    }
    .point--cont {
      @media screen and (min-width: $breakpoint-l) {
        padding-top:5em;
      }
    }
    .term {
      width:auto;
      max-width:none;
      margin-top:1em;
      margin-bottom:1em;
      p, li {
        font-size:1.25em;
        max-width:36em;
      }
      ul, ol {
        margin-top:0.5em;
      }
      li li {
        font-size:1em;
      }
    }
  }


  hr {
      margin:3em 0;
      @media screen and (min-width: $breakpoint-s) {
        margin:4em -1em;
      }
      @media screen and (min-width: $breakpoint-m) {
        margin:4em -2em;
      }
      @media screen and (min-width: $breakpoint-l) {
        margin:4em -3em;
      }
      @media screen and (min-width: $breakpoint-xl) {
        margin:6.25em -3em;
      }
  }

  figure {
    margin:0 -2.5em 4em;
    padding:1em;
    @media screen and (min-width: $breakpoint-s) {
//      margin:0 -1em 4em;
      margin:0 -3em 4em;
      padding:0;
    }
    @media screen and (min-width: $breakpoint-l) {
      margin:0 -3em 4em;
    }
    img, table {
      box-shadow:0 0 3em rgba(0,0,0,0.1);
      transition: box-shadow 1s;
      -webkit-transition: box-shadow 1s;
      @media screen and (min-width: $breakpoint-s) {
        box-shadow:0 0 3em rgba(0,0,0,0.1);
      }
      border-radius:0;
      max-width:100%;
    }
    table {
      caption {
        padding: 1em;
        font-weight: 700;
        font-size: 1.25em;
      }
      td,th {
        border:1px solid $brand-light-gray;
        text-align:left;
        padding: 1em;
        vertical-align:top;
        p { 
          font-size:1em;
          margin:0 1em 1em 0;
          max-width:none;
          &:last-child {
            margin-bottom:0;
          }
        }
      }
    }
    figcaption {
      margin-top: 1em;
      padding:0.5em 3em;
      display:block;
      @media screen and (min-width: $breakpoint-s) {
        margin-top: 1.5em;
        padding:0.5em 0;
      }
    }
  }


  //
  // layout options per section
  //

  // bars - per section
  .colour-bar {
    width:100%;
    left:0;
  }
  @media screen and (min-width: $breakpoint-s) {
    .colour-bar {
      width:100%;
      left:auto;
    }
    .section--canadian {
        .section__header .colour-bar {
            display:none !important;
        }
    }
    &.bar--right,
    &.section--intro,
    &.section--web-based,
    &.section--built-in-crawler {
      .colour-bar {
        width:200%;
        left:0;
      }
    }
    &.section--web-based .colour-bar{
      background: #FFE75D;
      height: 0;
      z-index: -1;
      padding-top: 100%;
      margin-left: -1em;
    }
    &.bar--left,
    &.section--customizable,
    &.section--canadian {
      .colour-bar {
        width:200%;
        right:0;
        left:auto;
      }
    }
  }

  &.section--cta {
    .colour-bar {
      background:$brand-yellow;
    }
  }


  // headings - per section
  &.heading--right,
  &.section--web-based {
    @media screen and (min-width: $breakpoint-m) {
      h1, .h1 {
        left:33.333333%;
        width:66.666666%;
      }
    }
  }
  &.section--intro {
    .section__header {

      margin-bottom:4em;
      h1, .h1 {
        width:100%;
        @media screen and (min-width: $breakpoint-xs) {
            padding-top:0;
        }
        @media screen and (min-width: $breakpoint-m) {
          padding-bottom:0;
        }
      }
      margin-top:0;
      @media screen and (min-width: $breakpoint-s) {
        margin-top:2em;
      }
      @media screen and (min-width: $breakpoint-l) {
        margin-top:4em;
      }
      .colour-bar {
        left:0;
        background:$brand-yellow;
          display:block;
      }
    }
  }
  &.section--web-based {
      h1, .h1 {
          margin-bottom:1em;
      }
  }
  // points - per section
  &.points--inset-one-column {
    .points {
      @media screen and (min-width: $breakpoint-m) {
        margin-left:12em;
      }
      @media screen and (min-width: $breakpoint-l) {
        margin-left:18em;
      }
    }
  }
}


// page-specific adjustments
body.onpoint-suite {
    .section.section--intro .section__header {
        h1, .h1 {
            padding-top:0.5em;
            margin-bottom:0.5em;
            .colour-bar {
               width:1em;
            }
        }
        p {
          margin-bottom:3em;
        }
        a {
          white-space:nowrap;
        }
    }
    h2 {
      font-weight:600;
    }
}


.home.onpoint-suite {
  .header {
    position:absolute;
    left:0;
    right:0;
  }
  #content::before {
    content: '';
    position: relative;
    height: 0;
    display: block;
    padding-bottom:80.25%;
    margin:5em 0 1em;
    background: url(/images/opsuite/illustrations/opsuite-dome.png) no-repeat -3em top;
    background-size: cover;
    @media screen and (min-width: 480px) {
      padding-bottom:24.375rem;
    }
    @media screen and (min-width: 694px) {
      background-size: contain;
      background-position: center top;
      padding-bottom:56.25%;
    }
    @media screen and (min-width: 768px) {
      margin:4em 0 -2em;
    }
    @media screen and (min-width:960px) {
      padding-bottom:33.75em ;
      margin:4em 0 -4em;
    }
    @media screen and (min-width:1200px) {
      margin:2em 0 -4em;
    }
  }
}





//custom style for the signup form on the search landing page
body.search .section--cta
 {
  background:$brand-yellow;
  margin-bottom:6em;
  padding:0 2em 2em;
  @media screen and (min-width: 1024px) {
    padding:0 4em 2em;
  }
  .colour-bar { display:none; }
}

body {
  .section.section--intro {
      margin-top:0.5em;
    .section__header {
      margin-bottom:0;
      .colour-bar {
        height:16px;
        height:0.2em;
        top:0;
      }
    }
    h2, .h2 {
//      margin-top:1.333333em;
        margin-top:0;
    }

    .section--cta {
        padding:0;
        margin-bottom:0;
        @media screen and (min-width: 640px) {
            padding:0 8.333% 2em;
        }
        @media screen and (min-width: 1024px) {
//            padding:0 4.5em 2em;
            padding:0 0 2em;
        }
        background:transparent;
        .section__header {
            margin-top:0;
        }
        h1, .h1 {
            margin:0 0 0.25em;
            font-weight:bold;
                font-size:1.44em;
            line-height:1.4em;
            margin-bottom:0.4em;
            @media screen and (min-width: 728px) {
                font-size:3.125em;
                margin-right:2em;
                font-size:2.5em;
                line-height:1.4em;
            }
        }
        h2, .h2 {
           font-weight:normal; 
           font-size:1.25em;
           margin-bottom:1em;
           @media screen and (min-width: 728px) {
               font-size:1.5em;
           }
        }
        p {
            font-size:0.8em;
        }

        input.email {
            outline:2px solid $brand-black;
            border-radius:0;
        }
        input.button {
            margin:0.5em 0 0.75em !important;
            @media screen and (min-width: 768px) {
                margin:0 !important;
                outline:2px solid $brand-black;
                border-radius:0 !important;
            }
        }
    }
  }
}


.search .section--packages {
  .h1{
      margin-bottom:0.5em;
  }
  .package--detail {
    border-bottom:1px dotted $brand-light-gray;
    padding:0.333333em 0 0.25em;
    overflow:hidden;
    .package--datum {
      font-weight:bold;
      float:right;
      display:inline-block;
    }
  }
}


// default
body .header .logo .logo--black  { display:none; }
.logo--color { }
