.toc {
  overflow-y:auto
}
.toc>.toc-list {
  overflow:hidden;
  position:relative
}
.toc>.toc-list li {
  list-style:none
}
.toc-list {
  margin:0;
  padding-left:10px
}
a.toc-link {
  color:currentColor;
  height:100%
}
.is-collapsible {
  max-height:1000px;
  overflow:hidden;
  transition:all 300ms ease-in-out
}
.is-collapsed {
  max-height:0
}
.is-position-fixed {
  position:fixed !important;
  top:0
}
.is-active-link {
  font-weight:700
}
.toc-link {
    &::before {
    background-color:#E1E2F1;
    opacity:0.25;
    content:' ';
    display:inline-block;
    height:inherit;
    left:0;
    margin-top:-1px;
    position:absolute;
    width:2px;
//    border-left:0.125em dotted #E1E2F1;
  }
  &:hover::before{
    opacity:1;
  }
}
.is-active-link::before {
  background-color:#54BC4B;
  border-left:transparent;
  opacity:1;
}