// Landing page specifc layout
// custom styles for the Search homepage

// removes offset for the points under the video. may not be necessary any longer
// body.onpoint-search {
//     .points {
//         .point {
//             @media screen and (min-width: $breakpoint-l) {
//                 margin-top:2em;
//                 margin-left:0.333333%;
//                 margin-right:3%;
//                 width:30%;
//             }
//         }
//         margin-top:0;
//     }
// }

.onpoint-search:after {
    content:'';
    background:none;
    background:#FFE75D;
    width:100em;
    height:100em;
    top:-112em;
    margin-left:-50em;
    margin-top:42em;

    @media screen and (min-width: 720px) {
        width:100em;
        width:300vh;
        height:100em;
        height:300vh;
        top:-100em;
        top:-300vh;
        margin-left:-50em;
        margin-left:-150vh;
        margin-top:42em;
    }
    border-radius:50%;
    position:absolute;
    z-index:-1;
    left:50%;
    opacity:1;
}

@keyframes ANIMATION0 {
  0%, 100%   { 
      transform: translateX(8em);
   }
  50%   { 
      transform: translateX(-8em);
   }
}
@keyframes ANIMATION1 {
  0%, 100% { 
    margin-top:42em;
  }
  50% { 
    margin-top:38em;
  }
}

.onpoint-search:after {
  animation:
      ANIMATION0 12s infinite ease,
      ANIMATION1 20s infinite ease;
}





.video-wrapper {
    width:100%; 

    padding-bottom:3em; 
    margin-bottom:3em;
    @media screen and (min-width: 840px) {
        padding-bottom:56.25%;
        margin-bottom:2em;
    }
    position:relative; 
    z-index:1;
}
#video-start:hover {
    cursor:pointer;
    .opyoutube-btn--play {
        bottom:0.25em;
        .play-icon {
            box-shadow:0 0.5em 0.75em rgba(0,0,0,0.25);
        }
    }
}
#video-start:active .opyoutube-btn--play {
    bottom:0;
    .play-icon {
        background-color:#3D3F4D;
        box-shadow:0 0.25em 0.5em rgba(0,0,0,0.5);
    }
}
#opyoutube {
    position:absolute;
    box-shadow: none;
//    border: 0.25em solid #3D3F4D;
    margin:0 -0.25em;
    background:white;
    opacity:0;
    z-index:-1;
    top:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    border:none;
}
.opyoutube-btn {
    position:absolute;
}
.opyoutube-btn--play {
    bottom:-0.5em;
    right:0;
    transition:bottom 0.125s ease;
    text-decoration:none;
    font-weight:700;
    font-size:1em;
    @media screen and (min-width: 640px) {
        bottom:0;
        font-size:1.25em;
    }
}
.opyoutube-btn--play .play-icon {
    background:#00b38f;
    border-radius:3em;
    width:0;
    height:0;
    padding:2.5em;
    overflow:hidden;
    line-height:0;
    text-indent:-9999em;
    display:block;
    float:right;
    box-shadow:0 0.125em 0.5em rgba(0,0,0,0.25);
    transition: box-shadow 0.125s ease;
    margin:-1.75em 0 0 0.75em;
    position:relative;
}
.opyoutube-btn--play .play-icon:before {
    content:'';
    position:absolute;
    z-index:1;
    left:50%;
    top:50%;
    margin:-0.666em -0.4em;

    // triangle
    width: 0; 
    height: 0; 
    border-top: 0.666em solid transparent;
    border-bottom: 0.666em solid transparent;
    border-left: 1em solid white;
}
#video-close {
    top:-1em;
    right:-1.5em;
    background:#3D3F4D;
    border:0.25em solid white;
    border-radius:2em;
    width:0;
    height:0;
    padding:1em;
    overflow:hidden;
    line-height:0;
    text-indent:-9999em;
    z-index:2;
    box-shadow:0 0.25em 0.5em rgba(0,0,0,0.5);
    opacity:0;
    transition:opacity 1s ease;
}
#video-close:before,
#video-close:after {
    content:'';
    position:absolute;
    width:0.25em;
    height:1em;
    background:white;
    top:50%;
    left:50%;
    margin:-0.5em -0.125em;
    transform: rotate(45deg);
}
#video-close:after {
    transform: rotate(-45deg);
}
.video-wrapper .h1 {
    font-size: 3.125em;
    line-height:1em;

    @media screen and (min-width: 640px) {
        margin-top: 0.25em;
        font-size: 4em;
    }
    @media screen and (min-width: 720px) {
        margin-top: 0.5em;
        font-size: 5em;
    }
    @media screen and (min-width: 840px) {
        position: absolute !important;
        margin-top: 0.333333em;
        font-size: 6em;
    }
}
.video-wrapper .h1 .smaller {
    font-size:0.5em;
    line-height: 1.666666em;
    display:block;
}
.video-backdrop {
    pointer-events: none;
    opacity:0;
    transition:opacity 0.25s ease;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:75%;
    height:75vh;
    z-index:0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3f4c6b+0,3f4c6b+100&1+0,0+100;Blue+Grey+Flat */
    background: -moz-linear-gradient(top, rgba(0,0,0,0.333) 0%, rgba(63,76,107,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.333) 0%,rgba(63,76,107,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.333) 0%,rgba(63,76,107,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f4c6b', endColorstr='#003f4c6b',GradientType=0 ); /* IE6-9 */
}
body.show-the-video .video-backdrop,
body.show-the-video #opyoutube {
    opacity:1;
    transition:opacity 2s ease;
}
body.show-the-video #video-close {
    opacity:1;
    transition:opacity 1s ease;
}
body.show-the-video #opyoutube {
    z-index:1;
    box-shadow: 0 1em 1em rgba(0,0,0,0.25);
    transition:box-shadow 0.5s ease;
}





// onpoint search hp
body.onpoint-search .header .logo {
    .logo--black { display:block; }
    .logo--color { display:none; }
}

