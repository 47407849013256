// body

// Fibonacci-based font-size scale : multiply our base unit of 8 by each of the first few numbers in the Fibonacci series:
//  1 // excluded, too small
//  2 × 8 = 16
//  3 × 8 = 24
//  5 × 8 = 40
//  8 × 8 = 64
// 13 × 8 = 104
// 
$grid-base-unit:8px;
$font-scale-01: #{ 2*$grid-base-unit }; // 16
$font-scale-02: #{ 3*$grid-base-unit }; // 24
$font-scale-03: #{ 5*$grid-base-unit }; // 40
$font-scale-04: #{ 8*$grid-base-unit }; // 64
$font-scale-05: #{13*$grid-base-unit }; // 104



body {
  font-family:'AvenirNext-OP', 'Helvetica Neue', 'HelveticaNeue', 'Helvetica', sans-serif;
  font-size:$font-scale-01;
  color:$brand-black;
  line-height:1.5;
  @media screen and (min-width: $breakpoint-xl) {
    font-size:1.125em;
  }
  @media screen and (min-width: $breakpoint-xxl) {
    font-size:1.25em;
    font-size:1.25vw;
  }
}

a {
  color:$brand-black;
  a:link,
  a:visited,
  a:hover,
  a:active {
    color:inherit;
  }
}

a.cta {
  position:relative;
  &:before {
    content: '';
    position: absolute;
    background: $brand-yellow;
    bottom: 0;
    height: 0.25em;
    right: 0;
    left: 0;
    z-index: -1;
  }
}

.nav a {
  text-decoration:none;
//  text-transform:uppercase;
}

p, ul, ol {
  margin:0 0 1em;
  font-size:1.25em;
  @media screen and (min-width: $breakpoint-m) {
    font-size:1em;
  }
}

p {
  max-width:32em;
}

li {
  margin: 0.5em 0;
}

h1, .h1 {
  max-width:16em; // controls line length
  font-size:31px; // 32px would be more consistent with our design system but I've adjusted it here so that line lengths work better
  line-height:36px;
  margin:0 0 32px; // again, 1em would be a cleaner implementation, but we need to add back the pixel we took out from the font-size above
  padding-top:1em;
  @media screen and (min-width: $breakpoint-xs) {
    font-size:2em;
    line-height:1.125em;
    margin-bottom:0.75em;
  }
  @media screen and (min-width: $breakpoint-s) {
    font-size:3em;
  }
  @media screen and (min-width: $breakpoint-m) {
    padding-top:0.833333333333em;
    font-size:4em;
    line-height:1.125em;
  }
  @media screen and (min-width: $breakpoint-l) {
    font-size:4.5em;
  }
  @media screen and (min-width: $breakpoint-xl) {
    padding-top:0.41666666666em;
  }
  @media screen and (min-width: $breakpoint-xxl) {
    padding-top:0.2em;
  }
}

.h1 {
    display: block;
//    font-size: 2em;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
}
.h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
}
h2, .h2, h3, h4, h5, h6, { //NOTE headings h4-h6 aren't in use
  font-size:1.5em;
  line-height:1.333333333333;
  margin:2.333333em 0 0.666666em;
  max-width:none;
}
h3, h4, h5, h6 {
  font-size:1.25em; 
  margin-top:3em;
}
.section--intro { 
    h1, .h1 {
        font-weight:900;
        margin-bottom:32px;
    }
}

figcaption {
  color:#73737D;
  text-align:left;
  font-style:normal;
  font-size:0.8em;
}


hr {
  margin:4em 0;
  border:none;
  border-top:2px solid #E1E2F1;
}

small {
  font-size: 75%;
}

// kern specific letter combinations with a span and create an associated class here:
.kern-rt {
  letter-spacing:0.0666em;
}