// Site footer

.site-footer {
  padding-bottom:3em;
  h1, .h1 {
    font-size:1.25em;
    width:100%;
    max-width:none;
    padding-top:2em;
    margin-bottom:1em;
  }
  .section {
    padding-bottom:0;
    .colour-bar {
      background:$brand-lighter-gray
    }
  }
  .points .point {
    margin-top:0;
    margin-bottom:0.5em;
  }
}