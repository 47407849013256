
//Avenir Next

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fontshop.com
*/
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=32287eb5-595c-424d-8d72-d9829dd67c3e&fontids=721263,721281,721287");
@font-face{
    font-family:"AvenirNext-OP";//AvenirNextLTW01-Regular
    src: url("/fonts/AvenirNext/721263/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
    src: local('Avenir Next Regular'), local('AvenirNext-Regular'),
    url("/fonts/AvenirNext/721263/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix") format("eot"),url("/fonts/AvenirNext/721263/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"),url("/fonts/AvenirNext/721263/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"),url("/fonts/AvenirNext/721263/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family:"AvenirNext-OP";//Avenir Next LT W01 Demi
    src:url("/fonts/AvenirNext/721269/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix");
    src: local('Avenir Next Demi Bold'), local('AvenirNext-DemiBold'),
    url("/fonts/AvenirNext/721269/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix") format("eot"),url("/fonts/AvenirNext/721269/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"),url("/fonts/AvenirNext/721269/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"),url("/fonts/AvenirNext/721269/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face{
    font-family:"AvenirNext-OP";//Avenir Next LT W01 Bold
    src: url("/fonts/AvenirNext/721281/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix");
    src: local('Avenir Next Bold'), local('AvenirNext-Bold'),
    url("/fonts/AvenirNext/721281/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix") format("eot"),url("/fonts/AvenirNext/721281/14c73713-e4df-4dba-933b-057feeac8dd1.woff2") format("woff2"),url("/fonts/AvenirNext/721281/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff") format("woff"),url("/fonts/AvenirNext/721281/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face{
    font-family:"AvenirNext-OP";//AvenirNextLTW01-Heavy
    src: url("/fonts/AvenirNext/721287/3418f6be-70a5-4c26-af1d-c09a8642ca20.eot?#iefix");
    src: local('Avenir Next Heavy'), local('AvenirNext-Heavy'),
    url("/fonts/AvenirNext/721287/3418f6be-70a5-4c26-af1d-c09a8642ca20.eot?#iefix") format("eot"),url("/fonts/AvenirNext/721287/5c57b2e2-f641-421e-a95f-65fcb47e409a.woff2") format("woff2"),url("/fonts/AvenirNext/721287/181c847e-cdbc-43d5-ae14-03a81c8953b4.woff") format("woff"),url("/fonts/AvenirNext/721287/045d1654-97f2-4ff0-9d24-21ba9dfee219.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

